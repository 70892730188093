import dynamic from 'next/dynamic'
import Head from 'next/head'
import Image from 'next/image'
import Link from 'next/link'
import { documentToReactComponents,  } from '@contentful/rich-text-react-renderer'
import { BLOCKS, MARKS, INLINES } from '@contentful/rich-text-types';
import {websiteUrl, website} from '../lib/utils';
import Marques from '../components/marques';
import BlocReferences from '../components/bloc-references'

const Layout = dynamic(() => import('../components/layout-alpha'))
const HeaderPWA = dynamic(() => import('../components/header'))
const ZoneLien = dynamic(() => import('../components/zone-lien'))
const MenuSister = dynamic(() => import('../components/menu-sister'))


export default function PageTemplate({article, moreArticle, breadcrumbs, daughter, motherAndSister,  preview, siteEnv, marques}){
    

    const website_url = siteEnv == 'pwafr'? 'https://www.progressive-web-apps.fr/' : websiteUrl(siteEnv);
    
    const assetMap = new Map();


    const Heading2 = ({ children }) => <h2 className="mb-8 text-5xl md:text-5xl font-medium orange tracking-tighter leading-tight mt-5">{children}</h2>;
    const Bold = ({ children }) => <span className="font-bold">{children}</span>
    const Italic = ({ children }) => <span className="italic">{children}</span>
    const Code = ({ children }) => <span dangerouslySetInnerHTML={ { __html: children } }></span>
    const UlList = ({ children }) => <ul className="itemList">{children}</ul>
    const Text = ({ children }) => <p>{children}</p>;
    const Heading3 = ({ children }) => <h3 className="mb-4 text-4xl md:text-3xl font-medium tracking-tighter leading-tight mt-7">{children}</h3>;
    const Heading4 = ({ children }) => <h4 className="mb-0 text-3xl md:text-2xl font-medium tracking-tighter leading-tight mt-6">{children}</h4>;

    if(article?.contenu?.links?.assets?.block.length > 0){
        for (const asset of article?.contenu?.links?.assets?.block) {
          assetMap.set(asset.sys.id, asset);
        }
      }
      if(article?.blocsCollection?.total > 0 ){
        for ( let temp of article?.blocsCollection.items){
          for (const asset of temp.texte?.links?.assets?.block) {
            assetMap.set(asset.sys.id, asset);
          }
        }
      }
  
  
      const options = {
        renderMark: {
          [MARKS.BOLD]: text => <Bold>{text}</Bold>,
          [MARKS.CODE]: text => <Code>{text}</Code>,
          [MARKS.ITALIC]: text => <Italic>{text}</Italic>,
        },
        renderNode: {
          [INLINES.HYPERLINK]: ({ data }, children) => (
            <a
              className="underline"
              href={data.uri}
              target={`${data.uri.startsWith(website_url) ? '_self' : '_blank'}`}
              rel={`${data.uri.startsWith(website_url) ? '' : 'noopener noreferrer'}`}
            >{children}</a>
          ),
          [BLOCKS.HEADING_2]: (node, children) => <Heading2>{children}</Heading2>,
          [BLOCKS.HEADING_3]: (node, children) => <Heading3>{children}</Heading3>,
          [BLOCKS.HEADING_4]: (node, children) => <Heading4>{children}</Heading4>,
          [BLOCKS.UL_LIST]: (_node, children) => {
            return (
              <UlList>
                {children.map((child, i) => {
                  return child;
                })}
              </UlList>
            );
          },
          [BLOCKS.TABLE_ROW]: (node, children) => {
            if (
              children.every((node) => node.nodeType === BLOCKS.TABLE_HEADER_CELL)
            ) {
              // all children are header cells, so we should wrap the row
              // with a <thead /> tag
              return (
                <thead>
                  <tr>{children}</tr>
                </thead>
              );
            } else {
              // not a header row, so we can render an ordinary <tr />
              return <tr>{children}</tr>;
            }
          },
          [BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,
          [BLOCKS.EMBEDDED_ASSET]: (node, children) => {
            const asset = assetMap.get(node.data.target.sys.id);
            return (
                  <figure>
                    <div className="asset">
                      <Image
                        src={`${asset?.url}?fm=webp&h=300&q=60`}
                        layout='fill'
                        objectFit="contain"
                        blurDataURL={`${asset?.url}?fm=webp&h=300&q=5`}
                        alt={asset?.title}
                        placeholder="blur"  
                      />
                    </div>
                    {asset?.description && <figcaption dangerouslySetInnerHTML={{ __html: asset?.description}} className="text-center italic"></figcaption>}
                  </figure>
              )
  
          }
        },
      };
      
      // var breadcrumbs = article?.breadcrumbs?.split('\n');

     let breadcrumbsTemp = [];
     let i = 0;
      let temp = [];
     /*   temp['text'] = 'Accueil';
      temp['link'] = '/';
      temp['alt'] = 'agence developpement web'; 
      breadcrumbsTemp[i] = temp;

      i++;*/

      //console.log("test !!!!!!!!",breadcrumbs);
      console.log(breadcrumbsTemp, temp);
      if(siteEnv == 'pwafr' && (!breadcrumbs || (breadcrumbs && breadcrumbs?.length == 1))){
        let temp = [];
        temp['text'] = 'Accueil';
        temp['link'] = '/';
        temp['alt'] = 'Agence developpement web'; 
        breadcrumbsTemp[i] = temp;
        i++;
        if(article?.typePage == "article"){
          let temp = [];
          temp['text'] = 'Actualités';
          temp['link'] = '/actualites';
          temp['alt'] = 'Actualités sur les progressives web apps'; 
          breadcrumbsTemp[i] = temp;
          i++;
        }else if(article?.typePage == "exemple"){
          let temp = [];
          temp['text'] = 'Cas d\'utilisation';
          temp['link'] = '/cas-d-utilisation';
          temp['alt'] = 'Exemples d\'utilisation des progressive web apps'; 
          breadcrumbsTemp[i] = temp;
          i++;
        }
      }

      if(breadcrumbs){
        breadcrumbs.forEach((element, index) => {
          let temp = [];
          temp['text'] = element.titleSmall || element.h1;
          temp['link'] = element.url;
          temp['alt'] = element.alt; 
          breadcrumbsTemp[i] = temp;
          i++;
        });
      }
      breadcrumbs = breadcrumbsTemp;


      function markdownToHtml(link){
        //\n to <br>
        if(link){
          link = link?.replace(/(?:\r\n|\r|\n)/g, '<br>');
  
          //check links [text](url text)
          let elements = link.match(/\[.*?\)/g);
          if( elements && elements.length > 0 ){
            for( let el of elements ){
              let text = "";
              let url = "";
              if(text = el.match(/\[(.*?)\]/)){
                text = text[1]; // get txt [>txt<]
              }
              if(url = el.match(/\((.*?) (.*?)\)/)){
                url = url[1];// get link without text (>link< txt)
              }else if (url = el.match(/\((.*?)\)/)){
                url = url[1];// get link without text (>link< txt)
              }
              if(url && text){
                link = link?.replace(el,'<a href="'+url+'" className="underline" target="_blank">'+text+'</a>')
              }
            }
          }
          return link
        }
        return ""
      }
      function checkMotherAndSister(){
        if((motherAndSister?.mother != null && motherAndSister?.mother?.length !=0) || (motherAndSister?.sister != null && motherAndSister?.sister?.length !=0)) return true
        return false;
      }
    return (
        <>
            <Layout preview={preview} siteEnv={siteEnv} breadcrumbs={breadcrumbs} >
                <Head>
                <title>{article?.metaTitle}</title>
                {article?.metaDescription && <meta name="description" content={article?.metaDescription} /> }
                <meta name="image" content={article?.fbImage ? website_url+"fichiers/page/"+ article?.fbImage : website_url+"img/logo.png"} />
                <meta name="robots" content="index, follow"/>
                <meta property="og:locale" content="fr"/>
                <meta property="og:site_name" content={siteEnv == "pwafr"? 'progressive-web-apps' : website(siteEnv)}/>
                <meta property="og:type" content={siteEnv == "Alphalives"? 'article' : 'website'} />
                {article?.url && <meta name="og:url" content={website_url.slice(0,-1)+ article?.url} /> }
                {article?.dateCreation && <meta property="article:published_time" content={article?.dateCreation}/>}
                {/* <meta property="article:tag" content=""/>
                <meta property="article:section" content=""/> */}
                {article?.auteur && <meta property="article:author" content={article?.auteur? article?.auteur?.prenom+" "+article?.auteur?.nom : ""}/> } 
                <meta property="article:modified_time" content={article?.dateModification || "2022-07-19T00:00:00.000Z"}/>
                <meta property="article:publisher" content={website_url.slice(0,-1)+ article?.url} />
                <meta property="fb:app_id" content="1795695950672896"/>
                <meta property="og:title" content={article?.fbTitle? article?.fbTitle : article?.metaTitle || ""} /> 
                <meta name="og:description" content={article?.fbDescription? article?.fbDescription : article?.metaDescription || ""} />
                <meta name="og:image" content={article?.fbImage ? website_url+"fichiers/page/"+ article?.fbImage : website_url+"img/logo.png"} />
                <meta name="twitter:card" content="photo"/>
                <meta name="twitter:site" content={siteEnv == "pwafr"? '@alphalives' : '@'+website(siteEnv)}/>
                <meta name="twitter:creator" content={siteEnv == "pwafr"? '@alphalives' : '@'+website(siteEnv)}/>
                {article?.url && <meta name="twitter:url" content={website_url.slice(0,-1)+ article?.url} /> }
                <meta name="twitter:title" content={article?.fbTitle? article?.fbTitle : article?.metaTitle || ""} /> 
                <meta name="twitter:description" content={article?.fbDescription? article?.fbDescription : article?.metaDescription || ""} />
                <meta name="twitter:image" content={article?.fbImage ? website_url+"fichiers/page/"+ article?.fbImage : website_url+"img/logo.png"} />
                {article?.url && <link href={website_url.slice(0,-1) + article?.url} rel="canonical"/> }

                </Head>
                <div className={siteEnv == 'alphalives' ? 'default-alphalives' : ''}>
                {/*siteEnv == 'alphalives' && <Header breadcrumbs={breadcrumbs} />*/}
                {siteEnv == 'pwafr' && <HeaderPWA />}
                
                <section className={"py-28 text-white text-center bg-fixed relative imagedBg"+(article?.url == "/mentions-legales" ? " image-fixed"+(siteEnv != "pwafr"? ' unfix' : ''): '')}>
                    <Image className="z-10 absolute inset-0 object-cover" 
                    layout='fill' 
                    src="https://images.ctfassets.net/a39bg4ghqkpe/5ty2QZFcHFXAhkkFRq9PNt/bd8f01a1032cd813da6adb0e7eb60c38/Alphalives-interne.png?q=60&fm=webp"
                    blurDataURL="https://images.ctfassets.net/a39bg4ghqkpe/5ty2QZFcHFXAhkkFRq9PNt/bd8f01a1032cd813da6adb0e7eb60c38/Alphalives-interne.png?q=0&fm=webp"
                    alt="zone titre"
                    placeholder="blur" />
        
                    <div className="container mx-auto px-5 grid grid-cols-1 gap-4 relative z-20 bg-transparent lg:grid-cols-12 text-base-top">
                      <div className="col-span-1 lg:col-start-3 lg:col-span-8">
                          <h1 className="2xl:text-4xl lg:text-3xl xl:text-4=3xl font-light leading-10 mb-20" dangerouslySetInnerHTML={{ __html: article?.h1}}></h1>
                          {siteEnv == "pwafr" && (<>
                          {article?.auteur && (
                              <>
                              <p className="text-base font-light mb-6 ">
                                  {article?.auteur?.prenom+" "+article?.auteur?.nom} - le {article?.dateCreation && new Date(article?.dateCreation).toLocaleDateString('fr-FR', {
                                  day: 'numeric',
                                  month: 'long',
                                  year: 'numeric',
                                  })}
                              </p>
                              </>
                          )}
                          </>)}
                          <p className="text-base text-left font-light " dangerouslySetInnerHTML={{ __html: markdownToHtml(article?.resume)}}>
                          </p>

                      </div>
                    </div>
                  {article?.showZoneContact && (
                    <>
                    <div className="container mx-auto px-5 grid grid-cols-1 gap-4 relative z-20 bg-transparent lg:grid-cols-12">
                        <div className="col-span-1 lg:col-start-3 lg:col-span-8 zoneContact ">
                          {article?.zoneContact? (documentToReactComponents(article?.zoneContact?.json, options))
                          : ( "Vous avez une question et souhaitez nous contacter directement ? N'attendez pas !" ) }
                          <button className="btn-secondary mt-4 block m-auto"> 
                              <Link href="/contact#zone7">
                                  <a className='btn-contact'>
                                  {article?.btnContactText? (article?.btnContactText)
                                  : ( "Contactez-nous" ) }
                                  </a>
                              </Link>
                            
                          </button>
                        </div>
                      </div>
                    </>
                  )}
                </section>
                {siteEnv == 'pwafr' && (
                  <>
                    <section className="container mx-auto px-5 grid gap-4 grid-cols-1 py-4 lg:grid-cols-12">
                      <div className="lg:col-start-3 col-span-1 lg:col-span-8 text-left">
                        <ol className="breadcrumb notAbsolute hidden lg:block" itemScope="" itemType="https://schema.org/BreadcrumbList">
                          {breadcrumbs?.map((bread, index) => (
                              <li className="breadcrumb-item" key={index} itemProp="itemListElement" itemScope="" itemType="https://schema.org/ListItem">
                                <a itemProp="item" href={bread?.link} alt={bread?.alt}>
                                  <span itemProp="name" dangerouslySetInnerHTML={{ __html: bread?.text}}></span> 
                                </a>
                                <meta itemProp="position" content={index+1} />
                              </li>
                          ))}
                        </ol>
                        
                      </div>
                    </section>
                  </>
                )}
                
                
                
                {
                article?.contenu && (
                    <>
                    <section className="article-area container mx-auto px-5 grid gap-4 grid-cols-1 py-4 lg:grid-cols-12 mb-10"  id="big-zone">
                    <div className="lg:col-start-2 col-span-1 lg:col-span-2 text-left sm:text-lg font-light">
                       {checkMotherAndSister() ? (<MenuSister urlPage={article?.url}  motherAndSister={motherAndSister} />) : ('')}
                       </div>
                        <div className="lg:col-start-4 col-span-1 lg:col-span-6 text-left sm:text-lg font-light documentReact">
                          {documentToReactComponents(article?.contenu?.json, options)}
                        </div>
                    </section>
                    </>
                )
                }
                
                <section>
                    { article?.blocsCollection?.items.map((element, index) => { return(   
                  

                      <div className={index %2 !=1 ? 'py-4 bg-white' : 'py-4 bg-gray'} key={index+"-"+element?.titreShort}>
                        



                          <div className="container mx-auto px-5 grid gap-4 grid-cols-1 py-2 lg:grid-cols-12 mb-10">

                          {
                          ! article?.contenu && index == 0  &&  (
                            <>
                            <div className="lg:col-start-2 col-span-1 lg:col-span-2 text-left sm:text-lg font-light">
                              {checkMotherAndSister() ? (<MenuSister urlPage={article?.url} motherAndSister={motherAndSister} />) : ('')}
                              
                            </div>
                            </>
                          )
                          }

                          <div className="lg:col-start-4 col-span-1 lg:col-span-6 text-left sm:text-lg font-light documentReact">
                              <h2 className="mb-8 text-5xl md:text-5xl font-medium orange tracking-tighter leading-tight mt-5">{element?.titreShort}</h2>
                              <div className="text-base  mb-4">
                              {documentToReactComponents(element?.texte?.json, options)}
                              </div>
                          </div>
                          </div>
                      </div>
                    
                    )})}
                </section>


                {/*article?.typePage !== "autre" && (
                    <>
                    <section className="article-area container mx-auto px-5 grid gap-4 grid-cols-1 py-4 lg:grid-cols-12" id="social">
                        <div className="lg:col-start-4 col-span-1 lg:col-span-6 text-left font-light">
                        <h3 className="text-center font-medium text-xl mb-6">Partager sur</h3>
                        <div className="lg:col-start-4 col-span-1 lg:col-span-6 text-center font-light">
                            <a className="btn facebook-link" target="_blank" rel="noreferrer noopener" href={"http://www.facebook.com/share.php?u="+website_url+article?.url}>
                                <i className="fab fa-facebook-f "></i>
                            </a>
                            <a className="btn twitter-link" data-count="none" rel="noreferrer noopener" data-lang="fr" target="_blank" href={"https://twitter.com/share?text="+article?.metaTitle+"&url="+website_url+article?.url}>
                                <i className="fab fa-twitter"></i>
                            </a>
                            <a className="btn linkdn-link" target="_blank" rel="noreferrer noopener" href={"http://www.linkedin.com/shareArticle?url="+website_url+article?.url}>
                                <i className="fab fa-linkedin-in"></i>
                            </a>
                        </div>
                    </div>
                    </section>
                    </>
                )*/}

                {article?.auteur && (
                    <>
                    <section className="author-area ">
                        <div className="container mx-auto px-5 grid gap-4 grid-cols-1 py-16 lg:grid-cols-12">
                        <div className="lg:col-start-3 lg:col-span-8 content-center col-span-1 text-left font-light flex flex-row">
                            <div className="flex-none mr-7">
                            <img
                                src={`${article?.auteur?.image?.url}?fm=webp&w=75&q=60`}
                                alt={article?.auteur?.nom+' '+ article?.auteur?.prenom}
                                className="rounded-full self-center"
                                width="60"
                                />
                            </div>
                            <div className="flex-1">
                                <div className="text-base mb-1">
                                {article?.auteur?.prenom+' '+ article?.auteur?.nom}
                                </div>
                                <div className="text-base">
                                {documentToReactComponents(article?.auteur?.texte?.json, options)}
                                </div>
                            </div>
                        </div>
                        
                        <div className="lg:col-start-3 lg:col-span-8 col-span-1 text-right font-light">
                            {article?.auteur?.facebook && (
                            <>
                                <a className="linkedin" target="_blank" href={article?.auteur?.facebook}>
                                    <i className="fab fa-facebook"></i>
                                </a>
                            </>
                            )}
                            {article?.auteur?.twitter && (
                            <>
                                <a className="linkedin" target="_blank" href={article?.auteur?.twitter}>
                                    <i className="fab fa-twitter"></i>
                                </a>
                            </>
                            )}
                            {article?.auteur?.linkedin && (
                            <>
                                <a className="linkedin" target="_blank" href={article?.auteur?.linkedin}>
                                    <i className="fab fa-linkedin-in"></i>
                                </a>
                            </>
                            )}
                        </div>

                        </div>
                    </section>
                    </>
                )}

                {daughter?.length > 0 && <ZoneLien liens={daughter} enSavoirPlus={article?.titreEnSavoirPlus} sousTitre={article?.soustitreEnSavoirPlus}></ZoneLien> }

                {article?.marquesBox && <Marques marques={article?.marquesCollection?.total ? article?.marquesCollection?.items : marques} isPage="true" limit={article?.limitMarques} title={article?.titleMarques} sousTitre={article?.subtitleMarques}></Marques>}
                {article?.showReference && (
                  <section id="references" className="bg-color-orange relative z-30 " style={{backgroundSize: "cover"}}>
                    <div className="site-wrapper-inner">
                      <div className="container mx-auto px-4 grid grid-cols-1 lg:grid-cols-12">
                        <div className="col-span-1 lg:col-start-3 lg:col-span-8">
                          <h2 className="text-5xl text-white mb-6 text-center">{article?.referenceTitle ? article?.referenceTitle :'Nos références'}</h2>
                          {article?.referenceSubtitle && <h3 className="text-4xl md:text-3xl text-white mb-6 text-center">{article?.referenceSubtitle}</h3>}
                          
                          <div className="grid grid-cols-2">
                            <BlocReferences limit="4" filter={article?.referenceToShow && article?.referenceToShow}></BlocReferences>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                )}
                </div>
            </Layout>
        </>
    )
}